.about-more-content{
 position: relative;
 scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.about-more-content .about-main-content-container{
  background:  linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ),url(../assets//image/about-more-bg2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding:20px;
  margin-top: 30px;
}

.about-more-content .top-content-container{
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  max-width: 300px;
  max-height: 300px;
  overflow-y: scroll;
  background-color: white;
  color: black;
  border-radius: 20px;
}

.about-more-content .top-content-container:last-child{
  margin-right: 0;
}

.about-more-content  .about-more-title{
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%,0);
  color: white;
  font-size:38px;
  font-weight: bold;
  white-space:nowrap;
}

.about-more-content .about-more-top-title{
  padding: 120px 80px;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(../assets//image/about-more.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  word-wrap:break-word;
}

.top-content-container .top-content-title{
  font-size:16px;
  font-weight: 800;
  word-wrap:break-word;
}

.top-content-container .top-content-content{
  font-size:14px;
}


.about-more-content .main-content-container{
  width: 40vw;
  /* height: 20vw; */
  background-color: white;
  color: black;
  /* background:  linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(../assets//image/about-more-bg2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  margin: 40px auto;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;
}

.main-content-container .main-content-container-title{
   font-size: 26px;
   font-weight: bold;
}

.main-content-container .main-content-container-desc{
  font-size:20px;
  text-align: center;
  padding: 30px;
}

.main-content-container .main-content-container-button{
  font-size:18px;
  text-align: center;
  color: white;
  background: rgb(252, 119, 3);
  border-radius: 20px;
}