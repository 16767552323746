.profile-container{
  display: flex;
  justify-content:center;
  flex-direction:column;
}

.profile-container .profile-content-container{
    padding: 40px 70px;
    border-radius: 3px;
    box-shadow: var(--ds-shadow-raised, 0 1px 1px rgba(9, 30, 66, 0.25), 0 0 1px 1px rgba(9, 30, 66, 0.13));
}

.profile-row{
  display: flex;
  width: 50%;
  flex-direction: column;
  margin: 0 0 30px 0;
}

.profile-container .profile-title-row{
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}

.profile-container .profile-title{
  font-size:1.6em;
  margin-left: 1vw;
  cursor: pointer;
}

.profile-container .profile-title-on{
  font-size:1.6em;
  margin-left: 1vw;
  cursor: pointer;
  border-bottom: 4px solid #1876D1;
}

.profile-container .profile-sub-title{
  font-size:1.5em;
  font-weight: normal;
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items:center;
  text-align: center;
}

.profile-container .button-container{
  width: 100%;
  display: flex;
  margin: 30px 0;
}

.profile-container .profile-CamPaign-title{
  font-size:1.5vw;
  font-weight: bold;
}


